import React from 'react';
import { Route, Routes } from "react-router-dom";
import HomeWrapper from './components/home/components/homeWrapper';
import ComputeNovaWrapper from './components/compute_nova/components/computeNovaWrapper';
import VolumesCinderWrapper from './components/volumes_cinder/components/volumesCinderWrapper';
import ImagesGlanceWrapper from './components/images_glance/components/imagesGlanceWrapper';
import NetworksNeutronWrapper from './components/networks_neutron/components/networksNeutronWrapper';
import BackupsDRWrapper from './components/backups_dr/components/backupsDRWrapper';
import IdentityKeystoneWrapper from './components/identity_keystone/components/identityKeystoneWrapper';
import DNSDesignateWrapper from './components/dns_designate/components/dnsDesignateWrapper';
import LBOctaviaWrapper from './components/lb_octavia/components/lbOctaviaWrapper';
import BillingConfigsWrapper from './components/billing/configs/billingConfigsWrapper';
import BillingDepositsWrapper from './components/billing/deposits/billingDepositsWrapper';
import BillingPricesWrapper from './components/billing/prices/billingPricesWrapper';
import BillingAnalyticsWrapper from './components/billing/analytics/billingAnalyticsWrapper';
import CustomersSupportWrapper from './components/customers_support/customersSupportWrapper';
import useAutoLogout from './components/_common/_hooks/sessionAutoLogout';

const AppRoutes = (props) => {
    useAutoLogout()
  
    return (
        <Routes>
            <Route exact path="/" element={<HomeWrapper />} />
            <Route exact path="/compute/servers" element={<ComputeNovaWrapper section="/servers" />} />
            <Route exact path="/compute/flavors" element={<ComputeNovaWrapper section="/flavors" />} />
            <Route exact path="/compute/keypairs" element={<ComputeNovaWrapper section="/keypairs" />} />
            <Route exact path="/compute/infrastructure" element={<ComputeNovaWrapper section="/infrastructure" />} />
            <Route exact path="/compute/quota-limits" element={<ComputeNovaWrapper section="/limits" />} />
            <Route exact path="/block-storage/volumes" element={<VolumesCinderWrapper section="/volumes" />} />
            <Route exact path="/block-storage/volume-types" element={<VolumesCinderWrapper section="/volume-types" />} />
            <Route exact path="/block-storage/snapshots" element={<VolumesCinderWrapper section="/snapshots" />} />
            <Route exact path="/block-storage/backups" element={<VolumesCinderWrapper section="/backups" />} />
            <Route exact path="/block-storage/volume-groups" element={<VolumesCinderWrapper section="/volume-groups" />} />
            <Route exact path="/block-storage/group-snapshots" element={<VolumesCinderWrapper section="/group-snapshots" />} />
            <Route exact path="/block-storage/group-types" element={<VolumesCinderWrapper section="/group-types" />} />
            <Route exact path="/block-storage/limits" element={<VolumesCinderWrapper section="/limits" />} />
            <Route exact path="/images" element={<ImagesGlanceWrapper />} />
            <Route exact path="/networks/l2networking" element={<NetworksNeutronWrapper section="/l2networking" />} />
            <Route exact path="/networks/l3networking" element={<NetworksNeutronWrapper section="/l3networking" />} />
            <Route exact path="/networks/security" element={<NetworksNeutronWrapper section="/security" />} />
            <Route exact path="/networks/fwaas" element={<NetworksNeutronWrapper section="/fwaas" />} />
            <Route exact path="/networks/vpnaas" element={<NetworksNeutronWrapper section="/vpnaas" />} />
            <Route exact path="/networks/limits" element={<NetworksNeutronWrapper section="/limits" />} />
            <Route exact path="/backups" element={<BackupsDRWrapper />} />
            <Route exact path="/identity/domains" element={<IdentityKeystoneWrapper section="/domains" />} />
            <Route exact path="/identity/regions" element={<IdentityKeystoneWrapper section="/regions" />} />
            <Route exact path="/identity/projects" element={<IdentityKeystoneWrapper section="/projects" />} />
            <Route exact path="/identity/users" element={<IdentityKeystoneWrapper section="/users" />} />
            <Route exact path="/identity/groups" element={<IdentityKeystoneWrapper section="/groups" />} />
            <Route exact path="/identity/roles" element={<IdentityKeystoneWrapper section="/roles" />} />
            <Route exact path="/identity/services-catalog" element={<IdentityKeystoneWrapper section="/services-catalog" />} />
            <Route exact path="/dns/zones" element={<DNSDesignateWrapper section="/zones" />} />
            <Route exact path="/dns/reverse-dns" element={<DNSDesignateWrapper section="/reverse-dns" />} />
            <Route exact path="/dns/pools" element={<DNSDesignateWrapper section="/pools" />} />
            <Route exact path="/dns/service-status" element={<DNSDesignateWrapper section="/service-status" />} />
            <Route exact path="/dns/tlds" element={<DNSDesignateWrapper section="/tlds" />} />
            <Route exact path="/dns/tsigkeys" element={<DNSDesignateWrapper section="/tsigkeys" />} />
            <Route exact path="/dns/blacklists" element={<DNSDesignateWrapper section="/blacklists" />} />
            <Route exact path="/dns/limits" element={<DNSDesignateWrapper section="/limits" />} />
            <Route exact path="/loadbalancers" element={<LBOctaviaWrapper />} />
            <Route exact path="/billing-configs" element={<BillingConfigsWrapper />} />
            <Route exact path="/billing-prices" element={<BillingPricesWrapper />} />
            <Route exact path="/billing-deposits" element={<BillingDepositsWrapper />} />
            <Route exact path="/billing-analytics" element={<BillingAnalyticsWrapper />} />
            <Route exact path="/customers-support" element={<CustomersSupportWrapper />} />
            <Route path='*' element={<HomeWrapper />} />
        </Routes>
  );
}

export default AppRoutes;