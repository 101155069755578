import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './NavBar';
import { GrVirtualMachine } from "react-icons/gr";
import { LuNetwork } from "react-icons/lu";
import { ImDatabase } from "react-icons/im";
import { IoCopyOutline } from "react-icons/io5";
import { RiListSettingsLine } from "react-icons/ri";
import { MdAccountBalanceWallet } from "react-icons/md";
import { IoPricetags } from "react-icons/io5";
import { FaChartBar } from "react-icons/fa";
//import { LuDatabaseBackup } from "react-icons/lu";
import { MdSwitchAccount } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
//import { TbLoadBalancer } from "react-icons/tb";
import { CgMenuGridO } from "react-icons/cg";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { setDrawerOpened } from '../../store/reducers/drawerSlice';
import Constants from '../../config/constants';
import Tooltip from '@mui/material/Tooltip';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import Popover from '@mui/material/Popover';
import { CiServer } from "react-icons/ci";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { GiHouseKeys } from "react-icons/gi";
import { GiServerRack } from "react-icons/gi";
import { SiPowerbi } from "react-icons/si";
import { GrVirtualStorage } from "react-icons/gr";
import { FaStream } from "react-icons/fa";
import { PiFrameCorners } from "react-icons/pi";
import { LuDatabaseBackup } from "react-icons/lu";
import { LuGroup } from "react-icons/lu";
import { AiOutlineGroup } from "react-icons/ai";
import { GrObjectUngroup } from "react-icons/gr";
import { FaEthernet } from "react-icons/fa6";
import { TbRouteSquare2 } from "react-icons/tb";
import { MdOutlineSecurity } from "react-icons/md";
import { GiBrickWall } from "react-icons/gi";
import { MdVpnLock } from "react-icons/md";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { HiMiniUserGroup } from "react-icons/hi2";
import { RiDatabaseFill } from "react-icons/ri";
import { GrProjects } from "react-icons/gr";
import { ImUsers } from "react-icons/im";
import { ImProfile } from "react-icons/im";
import { LuListTree } from "react-icons/lu";
import { FaRegRectangleList } from "react-icons/fa6";
//import { GiBackForth } from "react-icons/gi";
import { FaLayerGroup } from "react-icons/fa6";
import { GrStatusInfo } from "react-icons/gr";
import { FaGlobeAsia } from "react-icons/fa";
import { PiLockKeyLight } from "react-icons/pi";
import { TbNotesOff } from "react-icons/tb";


const defaultServiceList = [
  {
    service: 'Compute (Nova)', 
    icon: GrVirtualMachine, 
    navigation: '/compute', 
    submenu_opened: false,
    submenu: [
      {title: 'Servers', icon: CiServer, navigation: '/compute/servers'},
      {title: 'Flavors', icon: HiOutlineRectangleGroup, navigation: '/compute/flavors'},
      {title: 'Keypairs', icon: GiHouseKeys, navigation: '/compute/keypairs'},
      {title: 'Infrastructure', icon: GiServerRack, navigation: '/compute/infrastructure'},
      {title: 'Quota Limits', icon: SiPowerbi, navigation: '/compute/quota-limits'},
    ]},
  {
    service: 'Block Storage (Cinder)', 
    icon: ImDatabase, 
    navigation: '/block-storage',
    submenu_opened: false,
    submenu: [
      {title: 'Volumes', icon: GrVirtualStorage, navigation: '/block-storage/volumes'},
      {title: 'Volume Types', icon: FaStream, navigation: '/block-storage/volume-types'},
      {title: 'Snapshots', icon: PiFrameCorners, navigation: '/block-storage/snapshots'},
      {title: 'Backups', icon: LuDatabaseBackup, navigation: '/block-storage/backups'},
      {title: 'Volume Groups', icon: LuGroup, navigation: '/block-storage/volume-groups'},
      {title: 'Group Snapshots', icon: AiOutlineGroup, navigation: '/block-storage/group-snapshots'},
      {title: 'Group Types', icon: GrObjectUngroup, navigation: '/block-storage/group-types'},
      {title: 'Quota Limits', icon: SiPowerbi, navigation: '/block-storage/limits'},
  ]},
  {service: 'Images (Glance)', icon: IoCopyOutline, navigation: '/images'},
  {
    service: 'Networks (Neutron)', 
    icon: LuNetwork, 
    navigation: '/networks',
    submenu_opened: false,
    submenu: [
      {title: 'L2 Networking', icon: FaEthernet, navigation: '/networks/l2networking'},
      {title: 'L3 Networking', icon: TbRouteSquare2, navigation: '/networks/l3networking'},
      {title: 'Security', icon: MdOutlineSecurity, navigation: '/networks/security'},
      {title: 'Firewalls', icon: GiBrickWall, navigation: '/networks/fwaas'},
      {title: 'VPNs', icon: MdVpnLock, navigation: '/networks/vpnaas'},
      {title: 'Quota Limits', icon: SiPowerbi, navigation: '/networks/limits'},
    ]},
  //{service: 'Backup and DR', icon: LuDatabaseBackup, navigation: '/backups'},
  {
    service: 'Identity (Keystone)', 
    icon: MdSwitchAccount, 
    navigation: '/identity',
    submenu_opened: false,
    submenu: [
      {title: 'Domains', icon: HiMiniRectangleGroup, navigation: '/identity/domains'},
      {title: 'Regions', icon: RiDatabaseFill, navigation: '/identity/regions'},
      {title: 'Projects', icon: GrProjects, navigation: '/identity/projects'},
      {title: 'Users', icon: ImUsers, navigation: '/identity/users'},
      {title: 'Groups', icon: HiMiniUserGroup, navigation: '/identity/groups'},
      {title: 'Roles', icon: ImProfile, navigation: '/identity/roles'},
      {title: 'Services Catalog', icon: LuListTree, navigation: '/identity/services-catalog'},
    ]},
  {
    service: 'DNS (Designate)', 
    icon: TbWorldWww, 
    navigation: '/dns',
    submenu_opened: false,
    submenu: [
      {title: 'Zones', icon: FaRegRectangleList, navigation: '/dns/zones'},
      //{title: 'Reverse DNS', icon: GiBackForth, navigation: '/dns/reverse-dns'},
      {title: 'Top Level Domains', icon: FaGlobeAsia, navigation: '/dns/tlds'},
      {title: 'TSIG Keys', icon: PiLockKeyLight, navigation: '/dns/tsigkeys'},
      {title: 'Blacklists', icon: TbNotesOff, navigation: '/dns/blacklists'},
      {title: 'Pools', icon: FaLayerGroup, navigation: '/dns/pools'},
      {title: 'Service Status', icon: GrStatusInfo, navigation: '/dns/service-status'},
      {title: 'Quota Limits', icon: SiPowerbi, navigation: '/dns/limits'},
    ]
  },
  //{service: 'Loadbalancers (Octavia)', icon: TbLoadBalancer, navigation: '/loadbalancers'},
]

const drawerWidth = Constants.drawerWidth;

const listItemButtonStyle = {
  minHeight: 48,
  px: 2.5
}

const listItemIconStyle = {
  minWidth: 0,
  py: 1,
  justifyContent: 'center',
  color: 'primary.main',
  '&:hover': {
    color: 'primary.light'
  }
}

const listSubItemIconStyle = {
  minWidth: 0,
  py: 1,
  justifyContent: 'center',
  color: 'primary.main',
  '&:hover': {
    color: 'primary.light'
  }
}

const listItemTextStyle = {
  color: 'text.primary',
  '&:hover': {
    color: 'primary.main'
  }
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const CustomDrawer = (props) => {
  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
  const defaultTexts = useSelector(state => state.texts.langTexts);
  const open = useSelector(state => state.drawer.drawerOpened);
  const theme = useTheme();
  const purchasedService = useSelector(
    state => state.openstack.purchasedServicesAll)
  const [manageBilling, setManageBilling] = useState(false)
  const [billingNavList, setBillingNavList] = useState([])
  const [serviceList, setServiceList] = useState([...defaultServiceList])
  const [anchorElSubmenu, setAnchorElSubmenu] = useState(null);
  const [currentPopoverItem, setCurrentPopoverItem] = useState(null);

    const handleOpenSubmenu = (event,navigation) => {
      setCurrentPopoverItem(navigation)
      setAnchorElSubmenu(event.currentTarget)
    };
    
    const handleCloseSubmenu = () => {
      setCurrentPopoverItem(null)
      setAnchorElSubmenu(null)
    };
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeSubmenuOpenState = (navigation) => {
    const updated_menu = serviceList.map(item => {
        if (item.navigation === navigation) {
            item.submenu_opened = !item.submenu_opened
        }
        return item
    })
    setServiceList(updated_menu)
}

  
  const customersSupportNavList = [
    {title: defaultTexts.customersSupportNavLink, icon: LiaHandsHelpingSolid, navigation: '/customers-support'}
  ]
  
  useEffect(() => {
    const defaultBillingNavList = [
      {title: defaultTexts.billingConfigsNavLink, icon: RiListSettingsLine, section: Constants.billing_sections.billing_configs, navigation: '/billing-configs'},
      {title: defaultTexts.billingPricesNavLink, icon: IoPricetags, section: Constants.billing_sections.billing_prices, navigation: '/billing-prices'},
      {title: defaultTexts.billingDepositsNavLink, icon: MdAccountBalanceWallet, section: Constants.billing_sections.billing_deposits, navigation: '/billing-deposits'},
      {title: defaultTexts.billingAnalyticsNavLink, icon: FaChartBar, section: Constants.billing_sections.billing_analytics, navigation: '/billing-analytics'}
    ]
    if (isAuthenticated && purchasedService.length > 0) {
      const billingService = purchasedService.filter(
        service => service.service === Constants.config_service_billing_name)
      const allowBilling = purchasedService.filter(
        service => service.service === Constants.config_service_billing_name)[0].config_params.visible_to_service.includes(
        Constants.config_service_custom_admin_ui)
      setManageBilling(allowBilling)
      if (allowBilling) {
        const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
        const updatedBillingNavList = defaultBillingNavList.filter(
          component => billingConfiguration.filter(
            section => section.section === component.section && 
            section.permissions.includes(Constants.permission_read)))
        setBillingNavList(updatedBillingNavList)
      }
    }
  },[
    isAuthenticated, 
    purchasedService,
    defaultTexts
  ])

  const handleDrawerToggle = () => {
    dispatch(setDrawerOpened({drawerOpened: !open}));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar 
        position="fixed" 
        open={open} 
        enableColorOnDark 
        //sx={{ zIndex: 1500 }}
      >
        <Toolbar>
        {isAuthenticated && <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <CgMenuGridO />
          </IconButton>}
          <NavBar />
        </Toolbar>
      </AppBar>
      {isAuthenticated && <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{backgroundColor: "primary.main", pb: 1 }}>
          <IconButton
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 20,
              color: 'white',
              ...(!open && { display: 'none' }),
            }}
          >
            <CgMenuGridO />
          </IconButton>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? 
              <ChevronRightIcon sx={{color: 'white'}} fontSize="large" /> : 
              <ChevronLeftIcon sx={{color: 'white'}} fontSize="large" />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box 
          sx={{height: '100%',
            overflow: 'hidden', 
            position: 'relative'
          }}
        >
          <Box 
            sx={{
              height: '100%', 
              position: 'absolute', 
              top: 0, 
              bottom: 0, 
              left: 0, 
              right: -19, 
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <List>
              {serviceList.map((item, index) => 
                <Box key={index} sx={{position: "relative"}}>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={(event) => {
                      if (item.submenu) {
                        changeSubmenuOpenState(item.navigation)
                        if (!open) {
                          handleOpenSubmenu(event, item.navigation)
                        }
                      } else {
                        navigate(item.navigation)
                      }}}
                    sx={{...listItemButtonStyle,justifyContent: open ? 'initial' : 'center'}}
                  >
                    <Tooltip title={open ? "" : item.service} placement="right">
                      <ListItemIcon
                        sx={{...listItemIconStyle, mr: open ? 2 : 'auto'}}
                      >
                        <item.icon size={30} />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText 
                      primary={item.service}
                      primaryTypographyProps={{fontWeight: '400'}} 
                      sx={{ 
                        opacity: open ? 1 : 0,
                        ...listItemTextStyle
                      }} 
                    />
                    {item.submenu && open &&
                      <ListItemIcon
                        sx={{...listItemIconStyle, ml: 3}}
                      >
                        {item.submenu_opened ? <MdKeyboardArrowUp size={20} /> :
                        <MdKeyboardArrowDown size={20} />}
                      </ListItemIcon>
                    }
                  </ListItemButton>
                </ListItem>
                {open && item.submenu && item.submenu_opened && item.submenu.map((subitem, index) => 
                  <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={(event) => {navigate(subitem.navigation)}}
                    sx={{...listItemButtonStyle, justifyContent: open ? 'initial' : 'center'}}
                  >
                    <Tooltip title={open ? "" : subitem.title} placement="right">
                      <ListItemIcon
                        sx={{...listSubItemIconStyle, mx: open ? 2 : 'auto'}}
                      >
                        <subitem.icon size={24} />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText 
                      primary={subitem.title}
                      primaryTypographyProps={{fontWeight: '400'}} 
                      sx={{ 
                        opacity: open ? 1 : 0,
                        ...listItemTextStyle
                      }} 
                    />
                  </ListItemButton>
                </ListItem>
                )}
                {!open && item.submenu && item.navigation === currentPopoverItem &&
                  <Popover 
                    open={Boolean(anchorElSubmenu)}
                    onClose={handleCloseSubmenu}
                    anchorEl={anchorElSubmenu}
                    transformOrigin={{vertical: 0, horizontal: -70}}
                  >
                  {item.submenu.map((subitem, index) => 
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      onClick={(event) => {navigate(subitem.navigation); handleCloseSubmenu()}}
                      sx={{...listItemButtonStyle, justifyContent: open ? 'initial' : 'center'}}
                    >
                      <ListItemIcon
                        sx={{...listSubItemIconStyle, mr: 1}}
                      >
                        <subitem.icon size={24} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={subitem.title}
                        primaryTypographyProps={{fontWeight: '400'}} 
                        sx={{ 
                          ...listItemTextStyle
                        }} 
                      />
                    </ListItemButton>
                  </ListItem>)}
                  </Popover>
                }
              </Box>)}
            </List>
            {manageBilling && <Divider />}
            {manageBilling && <List>
              {billingNavList.map((item, index) => 
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={(event) => {navigate(item.navigation)}}
                  sx={{...listItemButtonStyle,justifyContent: open ? 'initial' : 'center'}}
                >
                  <Tooltip title={open ? "" : item.title} placement="right">
                    <ListItemIcon
                      sx={{...listItemIconStyle, mr: open ? 3 : 'auto'}}
                    >
                      <item.icon size={30} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText 
                    primary={item.title}
                    primaryTypographyProps={{fontWeight: '400'}} 
                    sx={{ 
                      opacity: open ? 1 : 0,
                      ...listItemTextStyle
                    }} 
                  />
                </ListItemButton>
              </ListItem>)}
            </List>}
              {purchasedService.find(s => 
                s.service_type === Constants.config_service_type_frontend &&
                s.service === Constants.config_service_custom_client_ui
              ) && <Divider />}
              {purchasedService.find(s => 
                s.service_type === Constants.config_service_type_frontend &&
                s.service === Constants.config_service_custom_client_ui
              ) && <List>
                {customersSupportNavList.map((item, index) => 
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={(event) => {navigate(item.navigation)}}
                  sx={{...listItemButtonStyle,justifyContent: open ? 'initial' : 'center'}}
                >
                  <Tooltip title={open ? "" : item.title} placement="right">
                    <ListItemIcon
                      sx={{...listItemIconStyle, mr: open ? 3 : 'auto'}}
                    >
                      <item.icon size={30} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText 
                    primary={item.title}
                    primaryTypographyProps={{fontWeight: '400'}} 
                    sx={{ 
                      opacity: open ? 1 : 0,
                      ...listItemTextStyle
                    }} 
                  />
                </ListItemButton>
              </ListItem>)}
            </List>}
          </Box>
        </Box>
      </Drawer>}
      <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: open ? `calc(100vw - ${drawerWidth}px)` : undefined}}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}
export default CustomDrawer;